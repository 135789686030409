import React from 'react'
import shape1 from '../../assets/images/massactions/profitcalc.png';
import offers from '../../assets/images/massactions/collectionoffers.png';
import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'

function trackClick() {
    if (typeof window !== `undefined` && typeof dataLayer !== `undefined` && typeof gtag !== `undefined`) {
        /*eslint no-undef: 0*/
        gtag('event', 'conversion', {'send_to': 'AW-10938296215/zCh5CJXIis4DEJfP5N8o'});
    }
}  

const AboutUs = () => {
    return (
        <div className="about-area ptb-100" id="mass-actions">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <div className="shape" style={{ display: "flex", flexDirection: "column", maxWidth: "90%"}}>
                                <img src={shape1} alt="banner" style={{ maxWidth: "100%" }} />
                                <br/>
                                <img src={offers} alt="banner" style={{ maxWidth: "100%"}} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Save Gas with Mass Actions</h2>
                                <p>With Mass Listings, Sweeping, Collection wide offers and other mass actions, you will never waste time or GAS securing your NFTs again!
                                    Our mass actions span across multiple marketplaces as well so you'll have the best exposure!
                                </p>
                                <ul className="about-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Save Gas
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Sweeping
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Mass Listings
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Collection Wide Offers
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Multi Market Actions
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        More!
                                        <img src={shape2} alt="banner" />
                                    </li>
                                </ul>
                                <a href="https://app.gm-tools.xyz" onClick={() => trackClick()} target="_blank" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Launch App <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs