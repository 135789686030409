import React from 'react'
import ReactWOW from 'react-wow'
import img13 from '../../assets/images/main-banner/banner-two/expertmode.png'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

function trackClick() {
    if (typeof window !== `undefined` && typeof dataLayer !== `undefined` && typeof gtag !== `undefined`) {
        /*eslint no-undef: 0*/
        gtag('event', 'conversion', {'send_to': 'AW-10938296215/zCh5CJXIis4DEJfP5N8o'});
    }
}

const MainBanner = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId='' 
                onClose={() => setIsOpen(!isOpen)} 
            />

            <div className="banner-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="banner-content">
                                <ReactWOW delay='.1s' animation='fadeInLeft'>
                                    <h1>Market Aggregator & NFT Alpha Tools</h1>
                                </ReactWOW>

                                <ReactWOW delay='.1s' animation='fadeInLeft'>
                                    <p>With powerful NFT tools and real time Aggregate NFT data from all popular markets, GM Tools will enable you to dominate the NFT market!</p>
                                </ReactWOW>

                                <div className="btn-box">
                                    <a href="https://app.gm-tools.xyz" onClick={() => trackClick()} target="_blank" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Launch App <span></span>
                                    </a>

                                    {/*<Link 
                                        to="#"
                                        onClick={e => {e.preventDefault(); openModal()}}
                                        className="video-btn popup-youtube"
                                    >
                                        <i className="flaticon-google-play"></i> Watch Video
                                    </Link>*/}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="banner-animation-image">
                                <img src={img13} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainBanner