import React from 'react'
import logo from '../../assets/images/logosolo.png'
import small1 from '../../assets/images/process/process-small1.png'
import small4 from '../../assets/images/process/process-small4.png'
import small5 from '../../assets/images/process/process-small5.png'
import small6 from '../../assets/images/process/process-small6.png'

const HowItWork = () => {
    return (
        <section className="process-area ptb-100 bg-fafafb" id="roadmap">
            <div className="container">
                <div className="section-title">
                    <h2>Roadmap</h2>
                    <p>lean execution of a solid roadmap will give birth to a powerful platform that will change your understanding of what's possible.</p>
                </div>

                <div className="row align-items-center m-0">
                    <div className="col-lg-6 col-md-12 p-0">
                        <div className="process-image">
                            <img src={logo} alt="banner" style={{maxWidth: "300px"}}/>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 p-0">
                        <div className="process-content">
                            <div className="row">
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex roadmap-complete align-items-center">
                                            <div className="image">
                                                <img src={small1} alt="banner" />
                                            </div>
                                            <h3>Build Analytics + Snipe Tools</h3>
                                            <div className="number">1</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex roadmap-complete align-items-center">
                                            <div className="image">
                                                <img src={small1} alt="banner" />
                                            </div>
                                            <h3>Marketing</h3>
                                            <div className="number">2</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex roadmap-complete align-items-center">
                                            <div className="image">
                                                <img src={small1} alt="banner" />
                                            </div>
                                            <h3>Launch to GM Keyholders</h3>
                                            <div className="number">3</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex roadmap-complete align-items-center">
                                            <div className="image">
                                                <img src={small4} alt="banner" />
                                            </div>
                                            <h3>Build Mass Actions</h3>
                                            <div className="number">4</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex roadmap-complete align-items-center">
                                            <div className="image">
                                                <img src={small5} alt="banner" />
                                            </div>
                                            <h3>Launch Monthly Subscriptions</h3>
                                            <div className="number">5</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small5} alt="banner" />
                                            </div>
                                            <h3>Advanced Features</h3>
                                            <div className="number">6</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item" style={{ marginLeft: "80px" }}>
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small5} alt="banner" />
                                            </div>
                                            <h3>Roadmap V2</h3>
                                            <div className="number">7</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6">
                                    <div className="single-box-item">
                                        <div className="d-flex align-items-center">
                                            <div className="image">
                                                <img src={small6} alt="banner" />
                                            </div>
                                            <h3>Launch GM Tools Access Pass NFT</h3>
                                            <div className="number">8</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork