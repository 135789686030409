import React from 'react'
import shape1 from '../../assets/images/our-mission/our-mission-shape1.png'
import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'
import mission1 from '../../assets/images/snipe/snipe.png'

const SnipeTools = () => {
    return (
        <section className="our-mission-area ptb-1000" id="snipe-tools">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <span className="sub-title">
                                    <i className="flaticon-investment"></i> Dominate
                                </span>
                                <h2>GM Snipe Tools</h2>
                                <p>Utilizing powerful APIs, we provide aggregate data from the top marketplaces so that you have up to date listings along with precise snipe tools to execute on the best deals.</p>
                                <ul className="our-mission-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Opensea Listings
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        X2Y2 Listings
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Looksrare Listings
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Bids
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Quick Buy
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Sweeping
                                        <img src={shape2} alt="banner" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={mission1} alt="banner" />
                            <div className="shape">
                                <img src={shape1} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SnipeTools