import React from 'react'
import start1 from '../../assets/images/project-start1.png'
import shape3 from '../../assets/images/shape/vector-shape3.png'

function handleTracking() {
    if (typeof window !== `undefined` && typeof dataLayer !== `undefined` && typeof gtag !== `undefined`) {
        /*eslint no-undef: 0*/
        gtag('event', 'conversion', {'send_to': 'AW-10938296215/og-fCJrI-cgDEJfP5N8o'});
    }
}

const StartProject = () => {
    return (
        <section className="project-start-area ptb-100" id="sign-up">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-image">
                            <img src={start1} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="project-start-content">
                            <h2>Sign up!</h2>
                            <p>Stay up to date, be the first to know when we release new updates!</p>
                            <div className='signUpForm'>
                            {/*<!-- Begin Mailchimp Signup Form -->*/}
                            <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
                            <div id="mc_embed_signup">
                            <form action="https://gmail.us9.list-manage.com/subscribe/post?u=ebe8c0ad3f42f47a1adae4913&amp;id=32f31b26d9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                <div id="mc_embed_signup_scroll">
                            <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                            <div className="mc-field-group">
                                <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                            </label>
                                <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
                            </div>
                                <div id="mce-responses" className="clear">
                                    <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                                    <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                                </div>    {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->*/}
                                <div style={{ position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_ebe8c0ad3f42f47a1adae4913_32f31b26d9" tabIndex="-1" value="" /></div>
                                <div className="clear"><input onClick={() => handleTracking()} type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
                                </div>
                            </form>
                            </div>

                            {/* <!--End mc_embed_signup--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape3} alt="banner" />
            </div>
        </section>
    )
}

export default StartProject