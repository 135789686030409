import React from 'react'
import icon1 from '../../assets/images/icons/bxs-bar-chart-alt-2.svg'
import icon2 from '../../assets/images/icons/bx-cross.svg'
import icon3 from '../../assets/images/icons/bx-list-plus.svg'
import shape2 from '../../assets/images/services/service-shape2.png'

const FeaturedService = () => {
    return (
        <div className="boxes-area pb-70" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={icon1} alt="banner" style={{ height: "70px" }} />
                            </div>
                            <h3>
                                <a href="#market-analytics">
                                    Market Analytics
                                </a>
                            </h3>
                            <p>We provide real time market analytics so you'll have the edge against the competition and never miss what's trending!</p>

                            <a href="#market-analytics" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Read More <span></span>
                            </a>

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={icon2} alt="banner" style={{ height: "70px" }} />
                            </div>
                            <h3>
                                <a href="#snipe-tools">
                                    Snipe Tools
                                </a>
                            </h3>
                            <p>Quick buy + real time listings and powerful filtering features will enable you to snipe the best deals the moment they hit the market!</p>
                            
                            <a href="#snipe-tools" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Read More <span></span>
                            </a>

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3">
                        <div className="single-boxes-item">
                            <div className="icon">
                                <img src={icon3} alt="banner" style={{ height: "70px" }} />
                            </div>
                            <h3>
                                <a href="#mass-actions">
                                    Mass Actions
                                </a>
                            </h3>
                            <p>Do everything in bulk + save gas! Mass listing, Collection wide offers & Sweeping will ensure you always get the best deals!</p>
                            
                            <a href="#mass-actions" className="default-btn">
                                <i className="flaticon-right"></i> 
                                Read More <span></span>
                            </a>

                            <div className="shape1">
                                <img src={shape2} alt="banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturedService