import React from 'react'
import { Helmet } from "react-helmet"

function scripts () {
    if (typeof window !== `undefined` && typeof dataLayer !== `undefined`) {
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NGT8SVR');

            window.dataLayer = window.dataLayer || [];
            /*eslint no-undef: 0*/
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'UA-232884707-1');
    }
}

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>GM Tools</title>
                <meta name="description" content="NFT Sniper Tools, NFT Analytics and NFT Sweeping" />
                <meta name="og:title" property="og:title" content="GM Tools - NFT Sniper Tools & Sweeping"></meta>
                <meta name="twitter:card" content="GM Tools - Dominate the NFT Market!"></meta>
                <meta property="og:image" content="https://gm-tools.xyz/static/logo-36de84e88275e7c799abb0003fe4b9b4.png" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-232884707-1" />
                { scripts() }
                <script src="https://code.jquery.com/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>
                <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
                <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
            </Helmet>
        </div>
    )
}

export default SEO
