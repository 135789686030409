import React from 'react'
import logo from "../../assets/images/altlogo.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className='col-lg-4 col-md-4'>
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" style={{ maxWidth: "200px" }} />
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <p>Copyright @{currentYear} <strong>GM Tools</strong> All rights reserved</p>
                        </div>
                        <div className="col-lg-4 col-md-4">
                        <ul className="social-link">
                                <li>
                                    <a href="https://discord.gg/gmsquad" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-discord'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/GMToolsNFT" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;