import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import logo from "../../assets/images/logo.png"

const Navbar = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    function trackDiscordClick() {
        setCollapsed(true);
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined` && typeof gtag !== `undefined`) {
            /*eslint no-undef: 0*/
            gtag('event', 'conversion', {'send_to': 'AW-10938296215/44C0CImClckDEJfP5N8o'});
        }
    }
    
    function trackTwitterClick() {
        setCollapsed(true);
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined` && typeof gtag !== `undefined`) {
            /*eslint no-undef: 0*/
            gtag('event', 'conversion', {'send_to': 'AW-10938296215/7EEpCMialckDEJfP5N8o'});
        }
    }

    function trackClick() {
        if (typeof window !== `undefined` && typeof dataLayer !== `undefined` && typeof gtag !== `undefined`) {
            /*eslint no-undef: 0*/
            gtag('event', 'conversion', {'send_to': 'AW-10938296215/zCh5CJXIis4DEJfP5N8o'});
        }
    }    

    React.useEffect(() => {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
    })

    const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a 
                                href="#top"
                                onClick={() => setCollapsed(true)} 
                                className="navbar-brand"
                            >
                                <img src={logo} alt="logo" />
                            </a>

                            <button 
                                onClick={toggleNavbar} 
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a 
                                            href="#top" 
                                            activeClassName="active"
                                            className="nav-link"
                                            onClick={() => setCollapsed(true)}
                                        >
                                            Home
                                        </a>
                                    </li>

                                    <li className="nav-item">
                                        <a 
                                            href="#features" 
                                            activeClassName="active"
                                            className="nav-link"
                                            onClick={() => setCollapsed(true)}
                                        >
                                            Features
                                        </a>
                                    </li>

                                    <li className='nav-item'>
                                        <a
                                            href="https://twitter.com/gmtoolsnft"
                                            activeClassName="active"
                                            className="nav-link"
                                            target="_blank"
                                            onClick={() => trackTwitterClick(true)}
                                        >
                                            <i className='bx bxl-twitter'></i>
                                        </a>
                                    </li>
                                    
                                    <li className='nav-item'>
                                        <a
                                            href="https://discord.gg/gmsquad"
                                            activeClassName="active"
                                            className="nav-link"
                                            target="_blank"
                                            onClick={() => trackDiscordClick()}
                                        >
                                            <i className='bx bxl-discord'></i>
                                        </a>
                                    </li>
                                    {/*
                                    <li className="nav-item">
                                        <a 
                                            href="#faq" 
                                            activeClassName="active"
                                            className="nav-link"
                                        >
                                            FAQ
                                        </a>
                                    </li>
                                    */}
                                </ul>
                            
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                    <a href="https://app.gm-tools.xyz" onClick={() => trackClick()} target="_blank" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        Launch App <span></span>
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
