import React from 'react'
import shape1 from '../../assets/images/analytics/sellwall.png';
import VolumeFilter from '../../assets/images/analytics/VolumeFilter.png';
import shape2 from '../../assets/images/our-mission/our-mission-shape2.png'

const MarketAnalytics = () => {
    return (
        <div className="about-area ptb-100" id="market-analytics">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <div className="shape" style={{ display: "flex", flexDirection: "row", maxWidth: "50%"}}>
                                <img src={shape1} alt="banner" style={{ maxWidth: "100%" }} />
                                <img src={VolumeFilter} alt="banner" style={{ maxWidth: "100%" }} />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Market Analytics For Data-Driven Insights</h2>
                                <p>With valuable data layed out in our beautiful dashboard, you will never miss what the market is up to!
                                    Powerful insights will enable a higher success rate whether you're a flipper, collector, investor or any other NFT hustler!</p>
                                <ul className="about-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Aggregate Data
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Real Time Data
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Data Charts
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Multi Market Listings
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Trending Collections
                                        <img src={shape2} alt="banner" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Minting Collections
                                        <img src={shape2} alt="banner" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketAnalytics