import React from 'react'
import { RecoilRoot } from 'recoil'
import GoTop from './GoTop'
import Seo from './SEO'

function scripts() {
    if(typeof window !== `undefined`){
        // <!-- Google Tag Manager (noscript) -->
        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NGT8SVR"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
        // <!-- End Google Tag Manager (noscript) -->
    }
}

const Layout = ({ children }) => {
    return (
        <RecoilRoot>
            <Seo />
            {children}
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
            {scripts()}
        </RecoilRoot>
    )
}

export default Layout
